import { Controller } from "svelte-comps/stores"
import { AudioPlayer } from "../../modules/AudioPlayer"
// import { AudioPlayer } from "../../modules/AudioPlayer_Simple"
import { AudioScheduler } from "../../modules/AudioScheduler"
import type { AudioBlockData } from "../../types/RadioTypes"
import { DateTime } from "luxon"

const POLL_INTERVAL = 5 * 1000 // 5 seconds

export class ResponseError extends Error {}

export interface RadioControllerContext {
	hasUserInteracted: boolean // if the user has clicked or interacted with the page (required to play audio in the browser)
	audioCode: string | undefined // the Luxedo Radio audio code (something like LUXE)
	playbackOffset: number // an offset for playback (e.g., player will start playing .5 seconds later)
	playing?: string // the name of the currently playing scene
	nextName?: string // the name of the upcoming scene
	player?: AudioPlayer // the AudioPlayer instance which is currently active
	lastUpdate?: number // the last time data was refreshed
}

class RadioCtrl extends Controller<RadioControllerContext> {
	declare interval: number
	declare scheduler: AudioScheduler

	declare worker: Worker
	declare isListening: boolean
	declare loadingPromise: Promise<AudioBlockData | null>

	constructor() {
		super({
			hasUserInteracted: false,
			audioCode: undefined,
			playbackOffset: 0,
			player: undefined,
			playing: undefined,
			lastUpdate: undefined,
			nextName: undefined,
		})

		this.initializeWorker()
		this.scheduler = new AudioScheduler(this.onPlaybackUpdate, this.onScheduleUpdate)
	}

	/**
	 * Begins (or restarts) a poll to listen to a specific luxedo radio audio code
	 * @param id the audio code
	 */
	async connectToAudioCode(id: string, didInteract?: boolean) {
		console.warn("CONNECTING TO CODE: ", id)
		console.warn("RadioController", this)

		id = id.toUpperCase()
		if (didInteract) this.update({ hasUserInteracted: true })

		await this.fetchPlaybackData(id) // to throw error if invalid
		if (!this.get().hasUserInteracted) {
			try {
				this.update({ audioCode: id })
				return await this.awaitInteraction()
			} catch {}
		}

		if (this.interval) clearInterval(this.interval)

		this.requestDataFromWorker(id)

		this.isListening = true
		this.update({ audioCode: id })

		this.interval = setInterval(() => {
			this.requestDataFromWorker()
		}, POLL_INTERVAL)
	}

	/**
	 * Simply resets the interval, starting now.
	 */
	async resync() {
		const { audioCode, playbackOffset } = this.get()
		this.changeOffset(-playbackOffset)

		if (audioCode) await this.connectToAudioCode(audioCode)
	}

	/**
	 * Disconnects from the current connected audio code.
	 */
	disconnect = async () => {
		this.isListening = false
		if (this.interval) clearInterval(this.interval)
		this.get().player?.stop()
		try {
			if (this.loadingPromise) await this.loadingPromise
		} catch {}
		this.scheduler.clearSchedule()

		this.store.set({ ...this.contextDefault, hasUserInteracted: true })
	}

	declare schedulerOffsetUpdateTimeout: number | undefined
	/**
	 * Updates the playback offset by the amount specified.
	 * @param changeBy the amount to change the playback offset by
	 */
	changeOffset(changeBy: number) {
		let offset = this.get().playbackOffset
		const newOffset = Math.round((offset + changeBy) * 100) / 100

		if (this.schedulerOffsetUpdateTimeout) {
			clearTimeout(this.schedulerOffsetUpdateTimeout)
			this.schedulerOffsetUpdateTimeout = undefined
		}

		this.schedulerOffsetUpdateTimeout = setTimeout(() => {
			this.scheduler.setOffset(newOffset)
			this.schedulerOffsetUpdateTimeout = undefined
		}, 750)

		this.update({ playbackOffset: newOffset })
	}

	/**
	 * Called by AudioScheduler to update this player instance (to be used for visualization)
	 * @param name the show name
	 * @param player the AudioPlayer instance
	 */
	onPlaybackUpdate = (name?: string, player?: AudioPlayer) => {
		this.update({ player, playing: name })
	}

	/**
	 * Called by AudioScheduler to update the upcoming player instance (used for rendering upcoming info).
	 * @param name the show name
	 * @param player the AudioPlayer instance
	 */
	onScheduleUpdate = (name?: string, player?: AudioPlayer) => {
		this.update({ nextName: name })
	}

	/**
	 * Set up event handlers to wait for user interaction in order to begin playback
	 */
	async awaitInteraction() {
		return new Promise<void>((res) => {
			const onInteract = () => {
				res()
				this.update({ hasUserInteracted: true })
				const { audioCode, player } = this.get()
				if (audioCode) this.connectToAudioCode(audioCode)
			}

			const onClick = () => {
				onInteract()
				document.removeEventListener("mousedown", onClick)
				document.removeEventListener("touchstart", onClick)
			}

			document.addEventListener("mousedown", onClick)
			document.addEventListener("touchstart", onClick)
		})
	}

	// #region xxxxxxxxxxxxxxxxxxxxxxxxx Data Management  xxxxxxxxxxxxxxxxxxxxxxxxxx

	/**
	 * Initializes the radioWorker instance to handle data pulling via a worker
	 */
	initializeWorker() {
		this.worker = new Worker("js/radioWorker.js")
		this.worker.onmessage = (event) => {
			const { status, data, message } = event.data
			if (status === "success") this.handleFetchedData(data)
			else console.error("[ERROR] Trying to fetch audio data:", message)
		}
	}

	/**
	 * Sends message to worker to fetch new data (which will then be handled by handleFetchedData)
	 * @param code the audio code
	 * @returns void
	 */
	requestDataFromWorker(code?: string) {
		const apiUrl: string = import.meta.env.VITE_API_URL
		code = code ?? this.get().audioCode
		if (!code) return

		this.worker.postMessage({
			code,
			apiUrl,
		})
	}

	/**
	 * Handles new data, provided by the web worker by parsing it and sending it to the scheduler instance.
	 * @param data the new AudioBlockDAta
	 * @returns void
	 */
	private handleFetchedData(data: AudioBlockData) {
		this.update({ lastUpdate: DateTime.now().toSeconds() })

		if (!data || !this.isListening) return

		if (data.deviceVersion && compareVersion(data.deviceVersion, "3.2.0") < 0)
			window.location.replace(`https://myluxedo.com/audio_player/${this.get().audioCode}`)

		const limitDecimal = (num: number) => Math.floor(num * 1000) / 1000
		if (data.current) data.current.start = data.current.start ? limitDecimal(data.current.start) : 0
		if (data.upcoming)
			data.upcoming = data.upcoming.map((item) => ({
				...item,
				start: limitDecimal(item.start),
			}))

		this.scheduler.updatePlaybackData(data)
	}

	// #endregion xxxxxxxxxxxxxxxxxxxxxx Data Management  xxxxxxxxxxxxxxxxxxxxxxxxxx
	// #region    xxxxxxxxxxxxxxxxxxxxxx OLD Data Management xxxxxxxxxxxxxxxxxxxxxxxxxx

	/**
	 * [DEPRECIATED] Fetches the audio data according to the current id (found in this.store).
	 * The method has been replaced with "handleFetchedData" to utilize workers (public/js/radioWorker.js)
	 */
	async refreshData(code?: string) {
		let data: AudioBlockData | null

		this.loadingPromise = this.fetchPlaybackData(code)
		data = await this.loadingPromise

		// ensure device version works with new playback type
		if (compareVersion(data?.deviceVersion ?? "1.1.1", "3.2.0") < 0)
			window.location.replace(`https://myluxedo.com/audio_player/${this.get().audioCode}`)

		if (!data) return // ! handle no data

		// Limit start timestamp decimal places (any further and a sync operation is always being called for the most minor discrepency)
		const limitDecimal = (num: number) => Math.floor(num * 1000) / 1000
		if (data.current) data.current.start = data.current.start ? limitDecimal(data.current.start) : 0
		if (data.upcoming)
			data.upcoming = data.upcoming.map((item) => ({
				...item,
				start: limitDecimal(item.start),
			}))

		this.update({ lastUpdate: DateTime.now().toSeconds() })

		// update scheduler with new data
		this.scheduler.updatePlaybackData(data)
	}

	/**
	 * Gets the audio playback data from the server. Only used when testing a code as the worker (public/js/radioWorker.js) handles data fetching now.
	 * @returns AudioBlockData
	 */
	private async fetchPlaybackData(code?: string): Promise<AudioBlockData | null> {
		const id = code ?? this.get().audioCode

		if (!id) return null

		const URL = `${import.meta.env.VITE_API_URL}api/radio/${id}`
		const res = await fetch(URL)

		if (res.status == 200) {
			const data: AudioBlockData = await res.json()
			return data
		} else if ([404, 400].includes(res.status)) {
			const message = await res.text()
			throw new ResponseError(message)
		} else {
			throw new ResponseError("An unknown error occurred, please refresh and try again...")
		}
	}

	// #endregion xxxxxxxxxxxxxxxxxxxxxx OLD Data Management  xxxxxxxxxxxxxxxxxxxxxxxxxx
}

/**
 * Checks if this device's fw version is at least the specified version
 * ---
 * Pulled from the device code (did not want to need to add that additional package for this one method)
 * @param fwVersion
 */
function compareVersion(deviceVersion: string, fwVersion: string) {
	const parseVersion = (version: string) => version.split(".").map(Number)

	if (!deviceVersion) return -1

	const [major1, minor1, patch1] = parseVersion(deviceVersion)
	const [major2, minor2, patch2] = parseVersion(fwVersion)

	if (major1 !== major2) {
		return major1 > major2 ? 1 : -1
	}
	if (minor1 !== minor2) {
		return minor1 > minor2 ? 1 : -1
	}
	if (patch1 !== patch2) {
		return patch1 > patch2 ? 1 : -1
	}
	return 0
}

export const RadioController = new RadioCtrl()
